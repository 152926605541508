<template>
  <div>
    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">About</div>
      <div class="right">
        <a href="app-notifications.html" class="headerButton">
          <ion-icon class="icon" name="notifications-outline"></ion-icon>
          <span class="badge badge-danger">4</span>
        </a>
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="section full">
        <img
          src="../../../public/frontend/assets/img/sample/photo/wide3.jpg"
          alt="image"
          class="imaged w-100 square"
        />
      </div>

      <div class="section mt-3 mb-3">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">About us</h2>
            Finapp is Bootstrap 4 based template for your wallet, banking,
            financial mobile projects.
          </div>
        </div>
      </div>

      <div class="section mt-3 mb-3">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Learn More</h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            mollis iaculis aliquet. Duis euismod nibh at neque gravida
            tincidunt. Nunc vitae fringilla augue. Nunc blandit tempor enim
            porttitor bibendum. In ac blandit quam. Quisque laoreet ligula eu
            commodo cursus. In efficitur convallis felis.
          </div>
        </div>
      </div>

      <div class="section full mt-3">
        <div class="carousel-single owl-carousel owl-theme">
          <div class="item">
            <img
              src="../../../public/frontend/assets/img/sample/photo/wide1.jpg"
              alt="alt"
              class="imaged w-100"
            />
          </div>
          <div class="item">
            <img
              src="../../../public/frontend/assets/img/sample/photo/wide2.jpg"
              alt="alt"
              class="imaged w-100"
            />
          </div>
          <div class="item">
            <img
              src="../../../public/frontend/assets/img/sample/photo/wide3.jpg"
              alt="alt"
              class="imaged w-100"
            />
          </div>
        </div>
      </div>

      <div class="section mt-3 mb-3">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Have a Question?</h2>
            <p>
              Feel free to contact us with any problems. We will get back to you
              as soon as possible.
            </p>
            <a href="app-contact.html" class="btn btn-primary"
              ><ion-icon name="mail-open-outline"></ion-icon> Contact</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- * App Capsule -->

    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
      <a href="app-index.html" class="item">
        <div class="col">
          <ion-icon name="pie-chart-outline"></ion-icon>
          <strong>Overview</strong>
        </div>
      </a>
      <a href="app-pages.html" class="item">
        <div class="col">
          <ion-icon name="document-text-outline"></ion-icon>
          <strong>Pages</strong>
        </div>
      </a>
      <a href="app-components.html" class="item">
        <div class="col">
          <ion-icon name="apps-outline"></ion-icon>
          <strong>Components</strong>
        </div>
      </a>
      <a href="app-cards.html" class="item">
        <div class="col">
          <ion-icon name="card-outline"></ion-icon>
          <strong>My Cards</strong>
        </div>
      </a>
      <a href="app-settings.html" class="item">
        <div class="col">
          <ion-icon name="settings-outline"></ion-icon>
          <strong>Settings</strong>
        </div>
      </a>
    </div>
    <!-- * App Bottom Menu -->
  </div>
</template>

<script>
import Parse from "parse";
import axios from "axios";

export default {
  name: "AboutView",

  components: {},

  data() {
    return {};
  },

  methods: {
    goBack() {
      //window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },
  },
};
</script>
