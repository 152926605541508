<template>
  <div>
    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="/" class="headerButton goBack">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Hesap İşlemleri</div>
      <div class="right"></div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="error-page">
        <div class="mb-2">
          <img
            src="../../../public/frontend/assets/img/verify.png"
            alt="alt"
            class="imaged square w200"
          />
        </div>
        <h1 class="title">Hesap onayı bekleniyor</h1>
        <div class="text mb-2">
          Girmiş olduğunuz veriler inceleniyor en kısa sürede hesabınız aktif
          olacaktır.
        </div>
        <div id="countDown" class="mb-1">
          <span class="spinner-border mt-3" role="status"></span>
        </div>

        <div class="fixed-footer">
          <div class="row">
            <div class="col-12 mb-2">
              <!--
              <a
                href="#"
                class="btn btn-danger btn-lg btn-block"
                data-bs-toggle="modal"
                data-bs-target="#ModalBasic"
                >Sözleşme</a
              >
-->

              <a
                href="tel:+08502511919"
                class="btn btn-danger btn-lg btn-block"
              >
                <ion-icon name="call-outline"></ion-icon>Müşteri Hizmetleri
              </a>
            </div>
            <div class="col-12 mb-2">
              <a href="" class="btn btn-danger btn-lg btn-block disabled"
                >Araç Ekle</a
              >
            </div>
          </div>
        </div>

        <div
          class="modal fade modalbox"
          id="ModalBasic"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-hidden="true"
          style="display: none"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Yakıtpay Hakkında</h5>
                <a href="#" data-bs-dismiss="modal">Kapat</a>
              </div>
              <div class="modal-body">
                <p>YAKITPAY NEDİR?</p>
                <p>
                  Kurumsal Şirketlerin Faydalandığı İndirimlerden Sizde
                  Faydalanabilirsiniz. Araç Sayınıza Ve Yakıt Tüketimize Göre
                  Anında Indirim Imkanı.
                </p>
                <p>
                  Akaryakıt Tüketiminiz İçin Tam Kontrol :
                  Aracınızı/Araçlarınızı Yakıtpay Ile Güvenli, Kolay Ve Anlık
                  Yönetebilirsiniz. Araç Bazında Limitlerinize Anlık Erişebilir
                  Ve Limitlerinizi Değiştirebilirsiniz. İstediğiniz Anda
                  Uygulamamızdan Raporlarınızı Kontrol Edebilirisiniz.
                </p>
                <p>
                  Pratik Ve Hızlı Akaryakıt İkmali : İstasyonda Vakit
                  Kaybetmeden Yakıt Ikmali Yapabilirsiniz. İstasyonda Ödeme Yok!
                  : Bütün Ödemelerinizi Uygulamamızdan Yapabilir,Çalışanlarınıza
                  Nakit Veya Kredi Kartı Vermek Zorunda Kalmazsınız.
                </p>
                <p>
                  Muhasebe Kolaylığı : Aylık Toplu Fatura Ile Muhasebe Ve Idari
                  Işlemlerinizin Iş Yükünü Azaltabilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
import Parse from "parse";
import axios from "axios";

export default {
  name: "AboutView",

  components: {},

  data() {
    return {};
  },

  methods: {
    goBack() {
      window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },
  },
};
</script>
