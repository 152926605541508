<style>
input[id="plaka"] {
  background: url(/public/img/plate.png) #fff bottom left no-repeat;

  background-size: 17px 30px;
  background-repeat: no-repeat;

  text-align: left !important;
  padding-left: 21px !important;
  font-weight: 700;
  width: 115px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
  /*color: #6e6e6e;*/
}
</style>
<template>
  <div>
    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Yakıtpay</div>
      <div class="right">
        <a href="#" @click="aracEkle" class="headerButton">
          <span class="btn btn-danger">Araç Ekle</span>
        </a>
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <vue-good-table
        refs="vuetable"
        :columns="columns"
        :line-numbers="false"
        :search-options="{
          enabled: true,
          placeholder: 'Araç Ara...',
        }"
        :pagination-options="{
          enabled: false,
          mode: 'records',
        }"
        styleClass="order-table vgt-table striped "
        :rows="rows"
        :fixed-header="true"
        max-height="800px"
        @on-page-change="onPageChange"
        @on-row-click="onRowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'plaka', type: 'desc' },
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'netAmount'">
            <span style="font-weight: 900; color: red">{{
              props.row.netAmount
            }}</span>
          </span>

          <span v-else-if="props.column.field == 'discount'">
            <span style="font-weight: 800; color: orangered">{{
              props.row.discount
            }}</span>
          </span>

          <span v-else-if="props.column.field == 'totalAmount'">
            <span style="font-weight: 400; font-size: small; color: green">{{
              props.row.totalAmount
            }}</span>
          </span>

          <!--@click="confirmMessage"-->
        </template>
      </vue-good-table>

      <!-- Form Action Sheet -->
      <div
        class="offcanvas offcanvas-bottom action-sheet"
        tabindex="-1"
        id="actionSheetForm"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title">Araç Detay Bilgi</h5>
        </div>
        <div class="offcanvas-body">
          <div class="action-sheet-content">
            <div class="licence-plate">
              <label class="label" for="tutar1">Plaka :</label>
              <input
                type="text"
                class="form-control"
                id="plaka"
                v-model="this.prows.plate"
                disabled
              />
            </div>
            <br />
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="form-label">Yakıt Tipi :</label>
                <p>{{ prows.yakittur }}</p>
              </div>

              <div class="input-wrapper">
                <label class="form-label">Taşıt Tanıma Birimi :</label>
                <p>{{ prows.cihaztip }}</p>
              </div>
              <!--    ARAÇ LİMİT EKRANI
              <div class="input-wrapper">
                <label class="form-label">Aylık Araç Limit :</label>
                <p>{{ prows.araclimit }}</p>
              </div>
          
          -->
            </div>
            <div class="form-group basic">
              <button
                type="button"
                class="btn btn-danger btn-block"
                data-bs-dismiss="offcanvas"
              >
                Geri
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-bottom action-sheet"
        tabindex="-1"
        id="aracEkleSheetForm"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title">Araç Ekle</h5>
        </div>
        <div class="offcanvas-body">
          <div class="action-sheet-content">
            <form>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <div class="row">
                    <div class="col-4">
                      <label class="label">Yakıt türü:</label>
                      <select
                        class="form-control custom-select"
                        id="exp-month"
                        v-model="carYakit"
                      >
                        <option value="Dizel">DİZEL</option>
                        <option value="Benzin">BENZİN</option>
                      </select>
                    </div>

                    <div class="col-6">
                      <label class="label">Taşıt Tanıma Birimi:</label>
                      <select
                        class="form-control custom-select"
                        id="exp-year"
                        v-model="carCihaz"
                      >
                        <option value="2">KART</option>
                        <option value="1">HALKA</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="licence-plate">
                <label class="label" for="tutar1">Plaka :</label>
                <input
                  type="text"
                  class="form-control"
                  width="185px;"
                  id="plaka"
                  v-model="carPlaka"
                />
              </div>
              <br />
              <div class="content-footer mt-05">
                * Sisteme yeni araç eklmek için ön kayıt yaptırmanız
                gerekmektedir.
              </div>
              <div class="form-group basic">
                <button
                  type="button"
                  class="btn btn-danger btn-block"
                  @click="carKaydet"
                >
                  Araç kaydet
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- * Form Action Sheet -->
      <!--
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-toggle="offcanvas"
        data-bs-target="#actionSheetForm"
      >
        Show
      </button>
-->
      <!-- * App Capsule-->

      <!-- App Bottom Menu -->
      <div class="section full mt-2">
        <!-- App Bottom Menu -->

        <div class="appBottomMenu">
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/mainView/' + userDatax.objectId }">
                <ion-icon name="home-outline"></ion-icon>
                <strong>Anasayfa</strong>
              </router-link>
            </div>
          </a>
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/mapView/' }">
                <ion-icon name="navigate-outline"></ion-icon>
                <strong>İstasyonlar</strong></router-link
              >
            </div>
          </a>
          <a class="item">
            <div class="col">
              <div class="action-button large danger">
                <router-link :to="{ path: '/fuelView/' + userDatax.objectId }">
                  <img
                    src="../../../public/frontend/assets/img/po.png"
                    alt="image"
                  />
                </router-link>
              </div>
            </div>
          </a>
          <a class="item active">
            <div class="col">
              <router-link :to="{ path: '/carsView/' + userDatax.objectId }">
                <ion-icon name="car-sport-outline"></ion-icon>
                <strong>Araçlarım</strong></router-link
              >
              <span class="badge badge-danger">{{ this.countCarsResult }}</span>
            </div>
          </a>
          <a class="item">
            <div class="col">
              <router-link :to="{ path: '/cardsView/' + userDatax.objectId }">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Kartlarım</strong></router-link
              >
            </div>
          </a>
        </div>
        <!-- * App Bottom Menu -->
      </div>

      <!-- App Sidebar -->
      <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
        <div class="offcanvas-body">
          <!-- profile box -->
          <div class="profileBox">
            <div class="image-wrapper">
              <img
                src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
                alt="image"
                class="imaged rounded"
              />
            </div>
            <div class="in">
              <strong>{{ cariData.adSoyad }}</strong>
              <div class="text-muted"></div>
            </div>
            <a
              href="#"
              class="close-sidebar-button"
              data-bs-dismiss="offcanvas"
            >
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
          <!-- * profile box -->

          <ul class="listview flush transparent no-line image-listview mt-2">
            <li>
              <a class="item" href="#" @click.prevent="profilview">
                <div class="icon-box bg-danger">
                  <ion-icon name="person-outline"></ion-icon>
                </div>
                <div class="in">Profil</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="fuelview">
                <div class="icon-box bg-danger">
                  <ion-icon name="speedometer-outline"></ion-icon>
                </div>
                <div class="in">Yakıt Alımları</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="cardsview">
                <div class="icon-box bg-danger">
                  <ion-icon name="card-outline"></ion-icon>
                </div>
                <div class="in">Kredi Kartı Bilgileri</div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="carsview">
                <div class="icon-box bg-danger">
                  <ion-icon name="car-outline"></ion-icon>
                </div>
                <div class="in">
                  Araç Bilgileri
                  <span class="badge badge-danger">{{
                    this.countCarsResult
                  }}</span>
                </div>
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="callUs">
                <div class="icon-box bg-danger">
                  <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                </div>
                Bize Ulaşın
              </a>
            </li>
            <li>
              <a class="item" href="#" @click.prevent="logoutUser">
                <div class="icon-box bg-danger">
                  <ion-icon name="exit-outline"></ion-icon>
                </div>
                Çıkış
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- * App Sidebar -->
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import axios from "axios";
import { paginateQuery } from "parse-paginate-query";

export default {
  name: "CarsView",

  components: {},

  data() {
    return {
      carYakit: "",
      carCihaz: "",
      carPlaka: "",
      cariData: "",
      info: "",
      cash: true,
      userDatax: [],
      countCarsResult: 0,
      userDatax: [],
      totalRecords: 0,
      page: 0,
      perPage: 10,

      show: false,
      data: {
        events: [],
      },

      form: {
        cKKkod: "",

        mUnvan: "",
        mdisc: "",
        cKod: "",
        mplaka: "",
        mprodId: "",
      },

      plaka: "",
      //show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "primary",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",

      columns: [
        {
          label: "Plaka",
          field: "plaka",
          html: true,
        },
        {
          label: "Durum",
          field: "isverified",
        },
        {
          label: "İşlem",
          field: "action",
          html: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
      ],
      rows: [],
      prows: [],
    };
  },

  methods: {
    mount() {},

    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/"); //DİKKAT
    },

    mainView() {
      window.location = "/mainview/" + this.userDatax.objectId;
    },

    callUs() {
      window.location = "/contactview";
    },

    carsview() {
      window.location = "/carsview/" + this.userDatax.objectId;
    },

    cardsview() {
      window.location = "/cardsview/" + this.userDatax.objectId;
    },

    fuelview() {
      window.location = "/fuelview/" + this.userDatax.objectId;
    },
    profilview() {
      window.location = "/profilview/" + this.userDatax.objectId;
    },

    onComplete: function () {
      next();
    },

    cashDeposit() {
      console.log("para yatırma");
      this.cash = false;
      var myOffcanvas = document.getElementById("addMoneyForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();
    },

    goBack() {
      //window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },
    onPageChange(params) {
      console.log(params.total);
      console.log(params.prevPage);
      console.log(params.currentPerPage);
      console.log(params.currentPage);
      //this.yakittablo(
      //  params.currentPerPage,
      //  params.currentPage * params.currentPerPage
      // );
      // params.currentPage - current page that pagination is at
      // params.prevPage - previous page
      // params.currentPerPage - number of items per page
      // params.total - total number of items in the table
    },
    onRowClick(params) {
      console.log("tıklanadı");
      console.log(params.row);
      this.prows = params.row;
      console.log(params.event);
      console.log(params.row.KKKod);

      var myOffcanvas = document.getElementById("actionSheetForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();

      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
    },
    aracEkle(params) {
      console.log("tıklanadı");

      var myOffcanvas = document.getElementById("aracEkleSheetForm");
      var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
      bsOffcanvas.show();

      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
    },

    carKaydet(params) {
      console.log("carKaydet");
      var userData = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userData);
      console.log(
        "******   :  " +
          this.carCihaz +
          " " +
          this.carYakit +
          " " +
          this.carPlaka
      );

      //Araç kaydı yapılıyor araç için carikod varsa yazılıyor yoksa cariKod ??????

      (async () => {
        const myNewObject = new Parse.Object("cars");
        myNewObject.set("fleetId", 0);
        myNewObject.set("cariKod", this.cariData.cariKod);
        myNewObject.set("aracTip", 1);
        myNewObject.set("aracLimit", 1);
        myNewObject.set("isVerified", "0");
        myNewObject.set("yakitTur", this.carYakit);

        myNewObject.set("serviceCardNo", 1);
        myNewObject.set("plaka", this.carPlaka);
        myNewObject.set("departmentCode", 1);
        myNewObject.set("CreditNo", 1);
        myNewObject.set("cihazTip", Number(this.carCihaz));
        try {
          const result = await myNewObject.save();
          // Access the Parse Object attributes using the .GET method
          console.log("cars created", result);
        } catch (error) {
          console.error("Error while creating cars: ", error);
        }
      })();
    },

    fueltablo() {
      var userData = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userData.cariKod);
      (async () => {
        const tempCars = Parse.Object.extend("temp");
        const tempQuery = new Parse.Query(tempCars);

        tempQuery.limit(30);
        tempQuery.skip(0);

        tempQuery.greaterThanOrEqualTo("Date", "2023-02-01");
        //tempQuery.lessThanOrEqualTo("Date", "2023-01-01");

        tempQuery.equalTo("cariKod", userData.cariKod);

        try {
          //console.log(tempQuery.QueryString["page"].ToString());

          const tempposts = await tempQuery.find();
          for (let i = 0; i < tempposts.length; i++) {
            console.log("Cari'ye ait  Plaka: " + tempposts[i].attributes.Plate);
            this.mplaka = tempposts[i].attributes.Plate;
            this.mTotalAmount = tempposts[i].attributes.TotalAmount;
            const params = { plaka: this.mplaka };

            (async () => {
              const cariResult = await Parse.Cloud.run("cariKod", params);
              console.log(cariResult);
              this.mcariKod = cariResult[0].attributes.cariKod;

              console.log(this.mcariKod);

              const param = { cariKod: this.mcariKod };
              (async () => {
                const Result = await Parse.Cloud.run("cari", param);
                console.log(Result);

                this.mtahsilatTutar = (
                  parseFloat(this.mTotalAmount) -
                  (parseFloat(this.mTotalAmount) / 100) * this.mdiscount
                ).toFixed(2);

                var cariKKkod = Result[0].attributes.kkKod;
                var musteriUnvan = Result[0].attributes.unvan;
                var disc = Result[0].attributes.discount;
                var cariKod = Result[0].attributes.cariKod;

                //console.log(musteriUnvan);

                if (Result[0].attributes.odemeTipi === 1) {
                  var tahsilatTip =
                    '<span class="badge badge-pill badge-outline-danger p-2 ">CARİ</span>';
                }
                if (Result[0].attributes.odemeTipi === 2) {
                  tahsilatTip =
                    '<span class="badge badge-pill badge-outline-success p-2 ">K.K - PROVİZYON</span>';
                }
                if (Result[0].attributes.odemeTipi === 3) {
                  tahsilatTip =
                    '<span class="badge badge-pill badge-outline-success p-2 ">K.K - VADELİ</span>';
                }
                if (Result[0].attributes.odemeTipi === 4) {
                  tahsilatTip =
                    '<span class="badge badge-pill badge-outline-success p-2 ">K.K - Ön Satış</span>';
                }
                if (Result[0].attributes.odemeTipi === 5) {
                  tahsilatTip =
                    '<span class="badge badge-pill badge-outline-success p-2 ">K.K - Anlık Tahsilat</span>';
                }

                if (Result[0].attributes.odemeTipi === 6) {
                  tahsilatTip =
                    '<span class="badge badge-pill badge-outline-primary p-2 ">DBS</span>';
                }

                if (tempposts[i].attributes.tahsilat === 1) {
                  var tahsilatDurum =
                    '<span class="badge badge-pill badge-outline-success p-2 ">OK</span>';
                }
                if (tempposts[i].attributes.tahsilat === 0) {
                  tahsilatDurum =
                    '<span class="badge badge-pill badge-outline-danger p-2 ">TAHSİLAT YOK!</span>';
                }

                if (
                  tempposts[i].attributes.tahsilatSonuc > 0 &&
                  tempposts[i].attributes.tahsilatSonuc < 99
                ) {
                  var tahsilatSonuc =
                    '<span class="badge badge-pill badge-outline-danger p-2 ">Genel Hata</span>';
                }
                if (tempposts[i].attributes.tahsilatSonuc === 99) {
                  tahsilatSonuc =
                    '<span class="badge badge-pill badge-outline-danger p-2 ">Limit Yetersiz</span>';
                }
                if (tempposts[i].attributes.tahsilatSonuc === 0) {
                  tahsilatSonuc =
                    '<span class="badge badge-pill badge-outline-success p-2 ">Tahsil Edildi.</span>';
                }

                var tarih = tempposts[i].attributes.Date;
                var D = tarih;
                const m = new Date(tarih);

                var tarihRow =
                  ("0" + m.getUTCDate()).slice(-2) +
                  "." +
                  ("0" + (m.getUTCMonth() + 1)).slice(-2) +
                  "." +
                  m.getUTCFullYear() +
                  " " +
                  ("0" + (m.getUTCHours() + 3)).slice(-2) +
                  ":" +
                  ("0" + m.getUTCMinutes()).slice(-2);

                //tarih.setHours(tarih.getHours()+3);
                console.log("Tarih:" + tarih);
                console.log(tarihRow);

                if (
                  Result[0].attributes.odemeTipi === 2 &&
                  tempposts[i].attributes.tahsilat === 1
                ) {
                  console.log(tempposts[i].id);
                  console.log(tempposts[i].attributes.Plate);
                  this.rows.push({
                    plate: tempposts[i].attributes.Plate,
                    Pid: tempposts[i].id,
                    unitPrice: tempposts[i].attributes.UnitPrice,
                    totalQuantity:
                      tempposts[i].attributes.TotalQuantity + " LT",
                    totalAmount: tempposts[i].attributes.TotalAmount + " TL",
                    tahsilat: tahsilatDurum,
                    tahsilatCevap: tahsilatSonuc,
                    KKKod: cariKKkod,
                    talepTarih: tarih,
                    tarihRow: tarihRow,
                    musteri: musteriUnvan,
                    discount: "%" + disc,
                    dealerName: tempposts[i].attributes.DealerName,
                    netAmount:
                      (
                        parseFloat(tempposts[i].attributes.TotalAmount) -
                        (parseFloat(tempposts[i].attributes.TotalAmount) /
                          100) *
                          disc
                      ).toFixed(2) + " TL",
                    odemeTipi: tahsilatTip,
                    mcariKod: cariKod,

                    plaka:
                      '<input type="text" class="form-control" id="plaka" disabled  value=' +
                      tempposts[i].attributes.Plate +
                      ">",

                    action:
                      '<button  class="btn btn-outline-primary text-black btn-rounded">İncele</button>',
                  });
                }
              })();
            })();
          }
        } catch (error) {
          console.error("Error while fetching temp", error);
        }
      })();
    },

    fueltablo2() {
      var userData = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userData);
      (async () => {
        // 'Post' is just an arbitrary class, replace it with your custom class
        const tempCars = Parse.Object.extend("temp");
        const tempQuery = new Parse.Query(tempCars);

        tempQuery.limit(5000);
        //tempQuery.limit(xlimit);
        tempQuery.skip(0);
        // tempQuery.skip(xskip);

        tempQuery.greaterThanOrEqualTo("Date", "2023-02-01");
        //tempQuery.lessThanOrEqualTo("Date", "2023-01-01");

        tempQuery.equalTo("cariKod", "C000002617");

        paginateQuery(tempQuery).then((response) => {
          const total = response.total; // The total of results in the db that match the query
          const results = response.results; // The results, returned by query.find()
          const limit = response.limit; // The limit of the query
          const skip = response.skip; // The skip of the query
          console.log("&&&&&&&&&&&&&&&&&&&&" + results);
        });

        //console.log("&&&&&&&&&&&&&&&&&&&&"+this.skip); tempposts[i].attributes.tahsilat === 0
        //tempQuery.equalTo("tahsilat", 0);
        try {
          //console.log(tempQuery.QueryString["page"].ToString());

          const tempposts = await tempQuery.find();
          for (let i = 0; i < tempposts.length; i++) {
            console.log(tempposts[i].attributes.Plate);
            console.log(`tempQuery found: ${JSON.stringify(tempposts)}`);

            this.mplaka = tempposts[i].attributes.Plate;
            this.mTotalAmount = tempposts[i].attributes.TotalAmount;
            console.log("plakaaa =" + this.mplaka);

            (async () => {
              // 'Post' is just an arbitrary class, replace it with your custom class
              const carsCari = Parse.Object.extend("cars");
              const carsQuery = new Parse.Query(carsCari);
              console.log("*******" + tempposts[i].attributes.Plate);
              carsQuery.equalTo("plaka", tempposts[i].attributes.Plate);
              try {
                // Fetches the posts with more than 1000 likes OR posts with the title 'My great post'
                const posts = await carsQuery.find();
                for (let i = 0; i < tempposts.length; i++)
                  console.log(posts[0].attributes.cariKod);
                console.log(`CarsQuery found: ${JSON.stringify(posts)}`);
                //  console.log("CarsQuery2 found: " + posts);
                let data = posts[0].attributes.cariKod;
                console.log(data);
                //console.log("Kkod==========" +posts[0].attributes.cariKod);

                (async () => {
                  // 'Post' is just an arbitrary class, replace it with your custom class
                  const customersCari = Parse.Object.extend("customers");
                  const customersQuery = new Parse.Query(customersCari);
                  customersQuery.equalTo(
                    "cariKod",
                    posts[0].attributes.cariKod
                  );
                  try {
                    // Fetches the posts with more than 1000 likes OR posts with the title 'My great post'
                    const customersposts = await customersQuery.find();
                    console.log(
                      `customersQuery found: ${JSON.stringify(customersposts)}`
                    );
                    this.mtahsilatTutar = (
                      parseFloat(this.mTotalAmount) -
                      (parseFloat(this.mTotalAmount) / 100) * this.mdiscount
                    ).toFixed(2);

                    var cariKKkod = customersposts[0].attributes.kkKod;
                    var musteriUnvan = customersposts[0].attributes.unvan;
                    var disc = customersposts[0].attributes.discount;
                    var cariKod = customersposts[0].attributes.cariKod;

                    if (customersposts[0].attributes.odemeTipi === 1) {
                      var tahsilatTip =
                        '<span class="badge badge-pill badge-outline-danger p-2 ">CARİ</span>';
                    }
                    if (customersposts[0].attributes.odemeTipi === 2) {
                      tahsilatTip =
                        '<span class="badge badge-pill badge-outline-success p-2 ">K.K - PROVİZYON</span>';
                    }
                    if (customersposts[0].attributes.odemeTipi === 3) {
                      tahsilatTip =
                        '<span class="badge badge-pill badge-outline-success p-2 ">K.K - VADELİ</span>';
                    }
                    if (customersposts[0].attributes.odemeTipi === 4) {
                      tahsilatTip =
                        '<span class="badge badge-pill badge-outline-success p-2 ">K.K - Ön Satış</span>';
                    }
                    if (customersposts[0].attributes.odemeTipi === 5) {
                      tahsilatTip =
                        '<span class="badge badge-pill badge-outline-success p-2 ">K.K - Anlık Tahsilat</span>';
                    }

                    if (customersposts[0].attributes.odemeTipi === 6) {
                      tahsilatTip =
                        '<span class="badge badge-pill badge-outline-primary p-2 ">DBS</span>';
                    }

                    if (tempposts[i].attributes.tahsilat === 1) {
                      var tahsilatDurum =
                        '<span class="badge badge-pill badge-outline-success p-2 ">OK</span>';
                    }
                    if (tempposts[i].attributes.tahsilat === 0) {
                      tahsilatDurum =
                        '<span class="badge badge-pill badge-outline-danger p-2 ">TAHSİLAT YOK!</span>';
                    }

                    if (
                      tempposts[i].attributes.tahsilatSonuc > 0 &&
                      tempposts[i].attributes.tahsilatSonuc < 99
                    ) {
                      var tahsilatSonuc =
                        '<span class="badge badge-pill badge-outline-danger p-2 ">Genel Hata</span>';
                    }
                    if (tempposts[i].attributes.tahsilatSonuc === 99) {
                      tahsilatSonuc =
                        '<span class="badge badge-pill badge-outline-danger p-2 ">Limit Yetersiz</span>';
                    }
                    if (tempposts[i].attributes.tahsilatSonuc === 0) {
                      tahsilatSonuc =
                        '<span class="badge badge-pill badge-outline-success p-2 ">Tahsil Edildi.</span>';
                    }

                    var tarih = tempposts[i].attributes.Date;
                    //tarih.setHours(tarih.getHours()+3);
                    console.log("Tarih:" + tarih);

                    this.rows.push({
                      Pid: tempposts[i].id,
                      unitPrice: tempposts[i].attributes.UnitPrice,
                      totalQuantity:
                        tempposts[i].attributes.TotalQuantity + " LT",
                      totalAmount: tempposts[i].attributes.TotalAmount + " TL",
                      tahsilat: tahsilatDurum,
                      tahsilatCevap: tahsilatSonuc,
                      KKKod: cariKKkod,
                      talepTarih: tarih,
                      tarihRow: tarihRow,
                      musteri: musteriUnvan,
                      discount: "%" + disc,
                      netAmount:
                        (
                          parseFloat(tempposts[i].attributes.TotalAmount) -
                          (parseFloat(tempposts[i].attributes.TotalAmount) /
                            100) *
                            disc
                        ).toFixed(2) + " TL",
                      odemeTipi: tahsilatTip,
                      mcariKod: cariKod,

                      plaka:
                        '<input type="text" class="form-control" id="plaka" disabled  value=' +
                        tempposts[i].attributes.Plate +
                        ">",

                      action:
                        '<button  class="btn btn-outline-danger text-black btn-rounded">İncele</button>',
                    });
                  } catch (error) {
                    console.log(`Error: ${error}`);
                  }
                })();
              } catch (error) {
                console.log(`Error: ${error}`);
              }
            })();
          }
        } catch (error) {
          console.error("Error while fetching temp", error);
        }
      })();
    },

    aractablo() {
      var userData = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userData.cariKod);

      const tempCars = Parse.Object.extend("cars");
      const query2 = new Parse.Query(tempCars);

      // if (userData.cariKod === "C0001") {
      query2.equalTo("cariKod", userData.cariKod);
      // }
      query2.limit(1000);
      query2.find().then(
        (results) => {
          for (let i = 0; i < results.length; i++) {
            const Post = Parse.Object.extend("cars");
            const tempCars = new Post();

            if (results[i].attributes.cihazTip === 1) {
              var cihaztip = "Halka";
            }
            if (results[i].attributes.cihazTip === 2) {
              cihaztip = "Kart";
            }
            if (results[i].attributes.isVerified === "X") {
              var isverified = "Aktif";
            }
            if (results[i].attributes.isVerified === "0") {
              isverified = "Onay Bekleniyor";
            }
            if (results[i].attributes.isVerified === "1") {
              isverified = "Kart Basılıyor";
            }
            if (results[i].attributes.isVerified === "2") {
              isverified = "Montaj Bekleniyor";
            }
            if (results[i].attributes.isVerified === "P") {
              isverified = "Pasif";
            }

            this.rows.push({
              Pid: results[i].id,
              plate: results[i].attributes.plaka,
              isverified: isverified,

              yakittur: results[i].attributes.yakitTur,
              cihaztip: cihaztip,
              araclimit: results[i].attributes.aracLimit,

              plaka:
                '<input type="text" class="form-control" id="plaka" disabled  value=' +
                results[i].attributes.plaka +
                ">",

              action:
                '<button  class="btn btn-outline-primary text-black btn-rounded">İncele</button>',
            });
          }
        },
        (error) => {
          if (typeof document !== "undefined")
            document.write(
              `Error while fetching tempCars: ${JSON.stringify(error)}`
            );
          console.error("Error while fetching tempCars", error);
        }
      );
    },
  },
  beforeMount() {
    this.aractablo();

    var userData = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userData.cariKod);
    console.log(userData.objectId);
    this.userDatax = userData;

    console.log("tablo yükleme başladımı");
    const carparams = { cariKod: userData.cariKod };
    const userparams = { cariKod: userData.cariKod };
    console.log(carparams);
    console.log(userparams);

    (async () => {
      console.log("carcount geldi mi");
      try {
        this.countCarsResult = await Parse.Cloud.run("countCars", carparams);
        console.log("countCars   :::  " + this.countCarsResult);
      } catch (error) {
        console.error("Error while retrieving object countCars", error);
      }
    })();

    (async () => {
      console.log("userInfo geldi mi");
      try {
        this.userInfoResult = await Parse.Cloud.run("cariInfo", userparams);
        console.log("UserInfo   :::  " + this.userInfoResult);
        this.cariData = this.userInfoResult[0];

        //console.log("UserInfo   :::  " + this.userInfoResult.ticariUnvan);
      } catch (error) {
        console.error("Error while retrieving object userInfo", error);
      }

      this.infom = JSON.stringify(this.cariData);
      console.log("infom: " + this.infom);
      localStorage.setItem("Info", JSON.stringify(this.cariData));
      console.log("cariData :: " + JSON.stringify(this.cariData));
      this.cariData = JSON.parse(JSON.stringify(this.cariData));
      console.log("cariData unvan :: " + this.cariData.cariKod);
    })();
  },
};
</script>
