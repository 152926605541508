import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueButtonSpinner from 'vue-button-spinner';

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]

import VueGoodTablePlugin from 'vue-good-table';
// import the styles 
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

var countCarsx = 0

import Parse from "parse";
Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('B02OPPjfdJQNrFy6oVCh9t6CDtWhln5ayleSM7Zl', 'R0uDNEOzcTmSG7oaTWU9q0HvwzCLDoahXUx6LGuR', 'eqZvlOwWr33DhJurQaQYEP1AB2W4IwNTLkpqVv7L'); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
Parse.serverURL = "https://parseapi.back4app.com/";

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)




import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


new Vue({


  router,
  store,
  render: h => h(App)
}).$mount('#app')
