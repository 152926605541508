import Vue from 'vue'
import VueRouter from 'vue-router'
import authenticate from "../auth/authenticate";
import LoginView from "../views/app/LoginView.vue";
import MainView from "../views/app/MainView.vue";
import AboutView from "../views/app/AboutView.vue";
import ProfilView from "../views/app/ProfilView.vue";
import FuelView from "../views/app/FuelView.vue";
import CarsView from "../views/app/CarsView.vue";
import CardsView from "../views/app/CardsView.vue";
import MapView from "../views/app/MapView.vue";
import ContactView from "../views/app/ContactView.vue";
import NewView from "../views/app/NewView.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
  },


  {
    path: "/contactview",
    name: "contactview",
    beforeEnter: authenticate,
    component: ContactView,
  },
  {
    path: "/RegisterView",
    name: "RegisterView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/app/RegisterView.vue"),
  },
  {
    path: "/app/OtpView",
    name: "OtpView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/app/OtpView.vue"),
  },
  {
    path: "/mainview/:Pid",
    name: "mainview",
    beforeEnter: authenticate,
    component: MainView,
  },
  {
    path: "/newview/:Pid",
    name: "newview",
    beforeEnter: authenticate,
    component: NewView,
  },
  {
    path: "/aboutview/:Pid",
    name: "aboutview",
    beforeEnter: authenticate,
    component: AboutView,
  },

  {
    path: "/profilview/:Pid",
    name: "Profilview",
    beforeEnter: authenticate,
    component: ProfilView,
  },
  {
    path: "/fuelview/:Pid",
    name: "fuelview",
    beforeEnter: authenticate,
    component: FuelView,
  },
  {
    path: "/carsview/:Pid",
    name: "carsview",
    beforeEnter: authenticate,
    component: CarsView,
  },
  {
    path: "/cardsview/:Pid",
    name: "cardsview",
    beforeEnter: authenticate,
    component: CardsView,
  },
  {
    path: "/mapview/",
    name: "mapview",
    beforeEnter: authenticate,
    component: MapView,
  },

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
