<template>
  <!-- * App Header -->
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      currentUser: "",
      infom: "",
    };
  },
};
</script>

<style></style>
