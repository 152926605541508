<template>
  <div>
    <!-- App Header -->
    <div class="appHeader">
      <div class="left">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon name="menu-outline"></ion-icon>
        </a>
      </div>
      <div class="pageTitle">Yakıtpay</div>
      <div class="right">
        <a href="tel:+08502511919" class="headerButton">
          <ion-icon name="call-outline"></ion-icon>
        </a>
      </div>
    </div>
    <!--* App Header -->

    <!-- App Capsule -->
    <div id="appCapsule">
      <div class="section full mt-2">
        <div class="section-title">İletişim için lütfen formu doldurunuz.</div>
        <div class="wide-block pt-2 pb-2">
          <form>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input
                  type="text"
                  class="form-control"
                  id="name5"
                  placeholder="Ad soyad"
                />
                <i class="clear-input">
                  <ion-icon
                    name="close-circle"
                    role="img"
                    class="md hydrated"
                    aria-label="close circle"
                  ></ion-icon>
                </i>
              </div>
            </div>

            <div class="form-group boxed">
              <div class="input-wrapper">
                <input
                  type="email"
                  class="form-control"
                  id="email5"
                  placeholder="Email"
                />
                <i class="clear-input">
                  <ion-icon
                    name="close-circle"
                    role="img"
                    class="md hydrated"
                    aria-label="close circle"
                  ></ion-icon>
                </i>
              </div>
            </div>

            <div class="form-group boxed">
              <div class="input-wrapper">
                <textarea
                  id="address5"
                  rows="4"
                  class="form-control"
                  placeholder="Mesaj"
                ></textarea>
                <i class="clear-input">
                  <ion-icon
                    name="close-circle"
                    role="img"
                    class="md hydrated"
                    aria-label="close circle"
                  ></ion-icon>
                </i>
              </div>
            </div>

            <div class="mt-1">
              <button
                type="submit"
                class="btn btn-danger btn-lg btn-block"
                data-bs-toggle="modal"
                data-bs-target="#DialogIconedSuccess"
              >
                Gönder
              </button>
            </div>
          </form>
        </div>
        <div class="content-footer mt-05">
          * En kısa sürede özel müşteri temsilciniz sizinle iletişime
          geçecektir.
        </div>
      </div>
    </div>

    <div
      class="modal fade dialogbox"
      id="DialogIconedSuccess"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-icon text-success">
            <ion-icon
              name="checkmark-circle"
              role="img"
              class="md hydrated"
              aria-label="checkmark circle"
            ></ion-icon>
          </div>
          <div class="modal-header">
            <h5 class="modal-title">Başarılı</h5>
          </div>
          <div class="modal-body">Mesajınız başarıyla kaydedildi.</div>
          <div class="modal-footer">
            <div class="btn-inline">
              <a href="#" class="btn" data-bs-dismiss="modal">Kapat</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * App Capsule -->

    <!-- App Bottom Menu -->
    <div class="section full mt-2">
      <!-- App Bottom Menu -->

      <div class="appBottomMenu">
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/mainView/' + userDatax.objectId }">
              <ion-icon name="home-outline"></ion-icon>
              <strong>Anasayfa</strong>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/mapView/' }">
              <ion-icon name="navigate-outline"></ion-icon>
              <strong>İstasyonlar</strong>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <div class="action-button large danger">
              <!--   <ion-icon name="add-outline"></ion-icon>-->
              <img
                src="../../../public/frontend/assets/img/po.png"
                alt="image"
              />
            </div>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/carsView/' + userDatax.objectId }">
              <ion-icon name="car-sport-outline"></ion-icon>
              <strong>Araçlarım</strong>
              <span class="badge badge-danger">{{ this.countCarsResult }}</span>
            </router-link>
          </div>
        </a>
        <a class="item">
          <div class="col">
            <router-link :to="{ path: '/cardsView/' + userDatax.objectId }">
              <ion-icon name="card-outline"></ion-icon>

              <strong>Kartlarım</strong>
            </router-link>
          </div>
        </a>
      </div>
      <!-- * App Bottom Menu -->
    </div>

    <!-- App Sidebar -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
      <div class="offcanvas-body">
        <!-- profile box -->
        <div class="profileBox">
          <div class="image-wrapper">
            <img
              src="../../../public/frontend/assets/img/sample/avatar/avatar1.jpg"
              alt="image"
              class="imaged rounded"
            />
          </div>
          <div class="in">
            <strong>{{ cariData.adSoyad }}</strong>
            <div class="text-muted"></div>
          </div>
          <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <!-- * profile box -->

        <ul class="listview flush transparent no-line image-listview mt-2">
          <li>
            <a class="item" href="#" @click.prevent="profilview">
              <div class="icon-box bg-danger">
                <ion-icon name="person-outline"></ion-icon>
              </div>
              <div class="in">Profil</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="fuelview">
              <div class="icon-box bg-danger">
                <ion-icon name="speedometer-outline"></ion-icon>
              </div>
              <div class="in">Yakıt Alımları</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="cardsview">
              <div class="icon-box bg-danger">
                <ion-icon name="card-outline"></ion-icon>
              </div>
              <div class="in">Kredi Kartı Bilgileri</div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="carsview">
              <div class="icon-box bg-danger">
                <ion-icon name="car-outline"></ion-icon>
              </div>
              <div class="in">
                Araç Bilgileri
                <span class="badge badge-danger">{{
                  this.countCarsResult
                }}</span>
              </div>
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="callUs">
              <div class="icon-box bg-danger">
                <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
              </div>
              Bize Ulaşın
            </a>
          </li>
          <li>
            <a class="item" href="#" @click.prevent="logoutUser">
              <div class="icon-box bg-danger">
                <ion-icon name="exit-outline"></ion-icon>
              </div>
              Çıkış
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- * App Sidebar -->
  </div>
</template>

<script>
import Parse from "parse";
// @ is an alias to /src
export default {
  name: "contactUs",

  components: {},
  data() {
    return {
      countCarsResult: 0,
      userInfoResult: [],
      cariData: "",
      info: "",
      cash: true,
      userDatax: [],
    };
  },

  methods: {
    goBack() {
      //window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },

    logoutUser() {
      localStorage.removeItem("userInfo");
      this.$router.push("/"); //DİKKAT
    },

    mainView() {
      window.location = "/mainview/" + this.userDatax.objectId;
    },

    callUs() {
      window.location = "/contactview";
    },

    carsview() {
      window.location = "/carsview/" + this.userDatax.objectId;
    },

    cardsview() {
      window.location = "/cardsview/" + this.userDatax.objectId;
    },

    fuelview() {
      window.location = "/fuelview/" + this.userDatax.objectId;
    },
    profilview() {
      window.location = "/profilview/" + this.userDatax.objectId;
    },
  },
  beforeMount() {
    var userData = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userData.cariKod);
    console.log(userData.objectId);
    this.userDatax = userData;

    console.log("tablo yükleme başladımı");
    const carparams = { cariKod: userData.cariKod };
    const userparams = { cariKod: userData.cariKod };
    console.log(carparams);
    console.log(userparams);

    (async () => {
      console.log("carcount geldi mi");
      try {
        this.countCarsResult = await Parse.Cloud.run("countCars", carparams);
        console.log("countCars   :::  " + this.countCarsResult);
      } catch (error) {
        console.error("Error while retrieving object countCars", error);
      }
    })();

    (async () => {
      console.log("userInfo geldi mi");
      try {
        this.userInfoResult = await Parse.Cloud.run("cariInfo", userparams);
        console.log("UserInfo   :::  " + this.userInfoResult);
        this.cariData = this.userInfoResult[0];

        //console.log("UserInfo   :::  " + this.userInfoResult.ticariUnvan);
      } catch (error) {
        console.error("Error while retrieving object userInfo", error);
      }

      this.infom = JSON.stringify(this.cariData);
      console.log("infom: " + this.infom);
      //localStorage.setItem("Info", JSON.stringify(this.cariData));
      console.log("cariData :: " + JSON.stringify(this.cariData));
      this.cariData = JSON.parse(JSON.stringify(this.cariData));
      console.log("cariData unvan :: " + this.cariData.cariKod);
    })();
  },
};
</script>
