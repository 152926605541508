<template>
  <div>
    <!-- App Capsule -->

    <div id="appCapsule" class="pt-0">
      <div class="login-form">
        <div v-if="point">
          <div class="section">
            <img
              src="../../../public/frontend/assets/img/sample/photo/logo.png"
              alt="image"
              class="form-image"
            />
          </div>
          <div class="section mt-1">
            <h1>Hızlı Giriş</h1>
            <h4>Sisteme kayıtlı telefon numaranızı giriniz</h4>
          </div>

          <div class="section mt-1 mb-5">
            <div class="form-group boxed">
              <div class="input-wrapper">
                <input
                  required
                  v-model="cep"
                  type="tel"
                  maxlength="16"
                  name="telefon"
                  masked="false"
                  v-mask="'0(###) ### ## ##'"
                  class="form-control"
                  id="cep"
                  placeholder="Telefon numarası"
                />

                <i class="clear-input">
                  <i class="bi bi-0-square"></i>
                </i>
              </div>
            </div>
            <div id="hata" class="toast-box toast-top danger">
              <div class="in">
                <div class="text">Telefon numarasını hatalı girdiniz..</div>
              </div>
            </div>

            <div class="form-links mt-1">
              <div>
                <a
                  href="#"
                  class="text-muted"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalRegister"
                  >Kayıt Ol</a
                >
              </div>
              <br />
              <div>
                <a
                  href="#"
                  class="text-muted"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalBasic"
                  >Bilgi</a
                >
              </div>
            </div>
            <br />

            <div class="section">
              <a
                href="#"
                class="btn btn-sm btn-text-secondary btn-block"
                data-bs-toggle="offcanvas"
                data-bs-target="#actionSheetDiscount"
              >
                <ion-icon
                  name="qr-code-outline"
                  role="img"
                  class="md hydrated"
                  aria-label="qr code outline"
                ></ion-icon>
                Kampanya kodunuz var mı?
              </a>
            </div>
            <div
              class="offcanvas offcanvas-bottom action-sheet"
              tabindex="-1"
              id="actionSheetDiscount"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title">Kampanya Kod Giriş</h5>
              </div>
              <div class="offcanvas-body">
                <div class="action-sheet-content">
                  <form>
                    <div class="form-group basic">
                      <div class="input-wrapper">
                        <label class="form-label" for="promoKod"
                          >Kampanya Kodu</label
                        >
                        <input
                          v-model="promoKod"
                          type="text"
                          class="form-control"
                          id="promoKod"
                          placeholder="Lütfen size iletilen kampanya kodunuzu giriniz."
                        />
                        <i class="clear-input">
                          <ion-icon
                            name="close-circle"
                            role="img"
                            class="md hydrated"
                            aria-label="close circle"
                          ></ion-icon>
                        </i>
                      </div>
                    </div>

                    <div class="form-group basic">
                      <button
                        type="button"
                        @click="promoGonder"
                        class="btn btn-danger btn-block"
                        data-bs-dismiss="offcanvas"
                      >
                        Kampanya Kodu ile Başvur
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade modalbox"
              id="ModalBasic"
              data-bs-backdrop="static"
              tabindex="-1"
              aria-hidden="true"
              style="display: none"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Yakıtpay Hakkında</h5>
                    <a href="#" data-bs-dismiss="modal">Kapat</a>
                  </div>
                  <div class="modal-body">
                    <p>YAKITPAY NEDİR?</p>
                    <p>
                      Kurumsal Şirketlerin Faydalandığı İndirimlerden Sizde
                      Faydalanabilirsiniz. Araç Sayınıza Ve Yakıt Tüketimize
                      Göre Anında Indirim Imkanı.
                    </p>
                    <p>
                      Akaryakıt Tüketiminiz İçin Tam Kontrol :
                      Aracınızı/Araçlarınızı Yakıtpay Ile Güvenli, Kolay Ve
                      Anlık Yönetebilirsiniz. Araç Bazında Limitlerinize Anlık
                      Erişebilir Ve Limitlerinizi Değiştirebilirsiniz.
                      İstediğiniz Anda Uygulamamızdan Raporlarınızı Kontrol
                      Edebilirisiniz.
                    </p>
                    <p>
                      Pratik Ve Hızlı Akaryakıt İkmali : İstasyonda Vakit
                      Kaybetmeden Yakıt Ikmali Yapabilirsiniz. İstasyonda Ödeme
                      Yok! : Bütün Ödemelerinizi Uygulamamızdan
                      Yapabilir,Çalışanlarınıza Nakit Veya Kredi Kartı Vermek
                      Zorunda Kalmazsınız.
                    </p>
                    <p>
                      Muhasebe Kolaylığı : Aylık Toplu Fatura Ile Muhasebe Ve
                      Idari Işlemlerinizin Iş Yükünü Azaltabilirsiniz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="login-form2">
              <div
                class="modal fade modalbox"
                id="ModalRegister"
                data-bs-backdrop="static"
                tabindex="-1"
                aria-hidden="true"
                style="display: none"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Hızlı Kayıt</h5>
                      <a href="#" data-bs-dismiss="modal">Kapat</a>
                    </div>
                    <div class="modal-body">
                      <div class="section full mt-1 mb-1">
                        <form action="">
                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label text-align: center" for="name"
                                >Ad Soyad</label
                              >

                              <input
                                type="text"
                                class="form-control"
                                id="name"
                                placeholder="Ad Soyad"
                                v-model="adSoyad"
                              />
                              <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                              </i>
                            </div>
                          </div>

                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label" for="cep">Cep No</label>
                              <input
                                v-model="cep"
                                type="tel"
                                maxlength="16"
                                name="telefon"
                                masked="false"
                                v-mask="'0(###) ### ## ##'"
                                class="form-control"
                                id="cep"
                                placeholder="Telefon numarası"
                              />

                              <i class="clear-input">
                                <i class="bi bi-0-square"></i>
                              </i>
                            </div>
                          </div>

                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label" for="email">Email</label>
                              <input
                                type="email1"
                                class="form-control"
                                id="email1"
                                placeholder="Email address"
                                v-model="email"
                              />
                              <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                              </i>
                            </div>
                          </div>

                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label" for="tckn">TCKN\VKN</label>
                              <input
                                type="text"
                                class="form-control"
                                id="tckn"
                                autocomplete="off"
                                placeholder="TC Kimlik No\Vergi No"
                                v-model="tckn"
                              />
                              <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                              </i>
                            </div>
                          </div>

                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label" for="firmaAdı"
                                >Firma Adı</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                id="firmaAdı"
                                autocomplete="off"
                                placeholder="Firma Adı"
                                v-model="firmaAdi"
                              />
                              <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                              </i>
                            </div>
                          </div>

                          <div class="form-group boxed">
                            <div class="input-wrapper">
                              <label class="label" for="promoKod"
                                >Kampanya Kodu</label
                              >
                              <input
                                type="text"
                                v-model="promoKod"
                                class="form-control"
                                id="promoKod"
                                autocomplete="off"
                                placeholder="Kampanya Kodu"
                              />
                              <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                              </i>
                            </div>
                          </div>

                          <div class="mt-1 text-start">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="customCheckb1"
                              />
                              <label
                                class="form-check-label"
                                for="customCheckb1"
                              ></label>
                              <label
                                ><a href="#">Sözleşmeyi </a>okudum, kabul
                                ediyorum.</label
                              >
                            </div>
                          </div>
                        </form>
                        <div class="mt-2 text-start">
                          <div class="form-check">
                            <button
                              class="btn btn-danger btn-block btn-lg"
                              @click="userKaydet"
                            >
                              Kaydet
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--    <div class="form-button-group">-->
            <div class="mt-2">
              <!-- eski buton
              <button
                id="login"
                class="btn btn-danger btn-block btn-lg"
                @click="sendOTP"
                role="link"
                :disabled="clicked"
              >
                Giriş
              </button>
-->
              <b-button
                class="btn btn-danger btn-block btn-lg"
                variant="success"
                :disabled="busy"
                @click="handleClick"
              >
                <!--  type="grow"-->
                <b-spinner
                  v-if="busy"
                  small
                  style="margin-bottom: 3px"
                ></b-spinner>
                Giriş
              </b-button>

              <br />
            </div>
            <!--
            <div class="section full mt-3">
              <div class="section-title">Kampanyalar</div>
              <div class="wide-block p-0 pt-2 pb-2">
              
                <div
                  class="story-block splide splide--slide splide--ltr splide--draggable is-active"
                  id="splide01"
                  style="visibility: visible"
                >
                  <div
                    class="splide__track"
                    id="splide01-track"
                    style="padding-left: 16px; padding-right: 16px"
                  >
                    <ul
                      class="splide__list"
                      id="splide01-list"
                      style="transform: translateX(0px)"
                    >
                      <li
                        class="splide__slide is-active is-visible"
                        id="splide01-slide01"
                        aria-hidden="false"
                        tabindex="0"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-component="stories"
                          data-time="5000"
                          data-bs-target="#StorywithButton"
                        >
                          <img
                            src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                            alt="alt"
                            class="imaged w-100 rounded"
                          />
                        </a>
                      </li>
                      <li
                        class="splide__slide is-visible"
                        id="splide01-slide02"
                        aria-hidden="false"
                        tabindex="0"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-component="stories"
                          data-time="5000"
                          data-bs-target="#StorywithButton"
                        >
                          <img
                            src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                            alt="alt"
                            class="imaged w-100 rounded"
                          />
                        </a>
                      </li>
                      <li
                        class="splide__slide seen is-visible"
                        id="splide01-slide03"
                        aria-hidden="false"
                        tabindex="0"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-component="stories"
                          data-time="5000"
                          data-bs-target="#StorywithButton"
                        >
                          <img
                            src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                            alt="alt"
                            class="imaged w-100 rounded"
                          />
                        </a>
                      </li>
                      <li
                        class="splide__slide seen is-visible"
                        id="splide01-slide04"
                        aria-hidden="false"
                        tabindex="0"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-component="stories"
                          data-time="5000"
                          data-bs-target="#StorywithButton"
                        >
                          <img
                            src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                            alt="alt"
                            class="imaged w-100 rounded"
                          />
                        </a>
                      </li>
                      <li
                        class="splide__slide passive is-visible"
                        id="splide01-slide05"
                        aria-hidden="false"
                        tabindex="0"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <img
                          src="../../../public/frontend/assets/img/sample/avatar/avatar5.jpg"
                          alt="alt"
                          class="imaged w-100 rounded"
                        />
                      </li>
                      <li
                        class="splide__slide passive"
                        id="splide01-slide06"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <img
                          src="../../../public/frontend/assets/img/sample/avatar/avatar6.jpg"
                          alt="alt"
                          class="imaged w-100 rounded"
                        />
                      </li>
                      <li
                        class="splide__slide passive"
                        id="splide01-slide07"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <img
                          src="../../../public/frontend/assets/img/sample/avatar/avatar7.jpg"
                          alt="alt"
                          class="imaged w-100 rounded"
                        />
                      </li>
                      <li
                        class="splide__slide passive"
                        id="splide01-slide08"
                        style="margin-right: 16px; width: 77.5px"
                      >
                        <img
                          src="../../../public/frontend/assets/img/sample/avatar/avatar8.jpg"
                          alt="alt"
                          class="imaged w-100 rounded"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
             
              </div>
            </div>
          -->
            <div
              class="modal fade stories"
              id="StorywithButton"
              data-bs-backdrop="static"
              tabindex="-1"
              style="display: none"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="story-image">
                    <img
                      src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                      alt="image"
                    />
                  </div>
                  <div class="modal-header">
                    <a href="#" class="profile-detail">
                      <img
                        src="../../../public/frontend/assets/img/sample/avatar/avatar4.jpg"
                        alt="avatar"
                        class="imaged w36 rounded me-1"
                      />
                      <div>
                        <h4>Ashley Graham</h4>
                        <span class="text-muted">5 minutes ago</span>
                      </div>
                    </a>
                    <a href="#" data-bs-dismiss="modal" class="close-stories">
                      <ion-icon
                        name="close"
                        role="img"
                        class="md hydrated"
                        aria-label="close"
                      ></ion-icon>
                    </a>
                  </div>
                  <div class="modal-body">
                    <div>
                      <h1>Another Title Here</h1>
                      <p>This is simple text for the story</p>
                      <a href="#" class="btn btn-danger">Do something</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- HERE-->
          </div>
        </div>
        <div v-if="!point">
          <!-- App Header -->

          <div id="otphata" class="toast-box toast-bottom danger">
            <div class="in">
              <div class="text">OTP şifresini hatalı girdiniz !</div>
            </div>
          </div>

          <div class="appHeader no-border transparent position-absolute">
            <div class="left">
              <ion-icon name="chevron-back-outline" @click="goBack"></ion-icon>
            </div>
            <div class="pageTitle"></div>
            <div class="right"></div>
          </div>
          <!-- * App Header -->

          <!-- App Capsule -->
          <div id="appCapsule">
            <div class="login-form">
              <div class="section mt-1">
                <h1>SMS Doğrulama</h1>
                <h4>Telefonuza gelen doğrulama kodunu giriniz</h4>
              </div>
              <div class="section mt-2 mb-5">
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <input
                      type="tel"
                      class="form-control verify-input"
                      id="smscode"
                      v-model="smscode"
                      placeholder="••••••"
                      maxlength="6"
                      @on-complete="handleOnComplete"
                    />
                  </div>
                </div>

                <!--    <div class="form-button-group">-->
                <div class="mt-2">
                  <button
                    class="btn btn-danger btn-block btn-lg"
                    @click="verfiyOTP"
                  >
                    <span>İleri</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- * App Capsule -->
        </div>
      </div>
    </div>

    <!-- * App Capsule -->
  </div>
</template>

<script>
// @ is an alias to /src
import ButtonSpinner from "vue-button-spinner";
import VueButtonSpinner from "vue-button-spinner";
import Parse from "parse";
import axios from "axios";
import router from "@/router";

export default {
  name: "LoginView",
  components: {
    ButtonSpinner,
    VueButtonSpinner,
  },

  data() {
    return {
      busy: false,
      isLoading: false,
      status: "",
      statusMessage: "Try me!",
      isLoading: false,
      status: "",
      count: 0,
      dismissSecs: 10,
      dismissCountDown: 0,
      showBottom: false,
      point: true,
      pointRegister: false,
      cep: "",
      promoKod: "",
      hashm: "",
      cepNo: "",
      firmaAdi: "",
      tckn: "",
      adSoyad: "",
      otp: "",
      username: "",
      smscode: "",
      email: "",
      password: "sanane",
      // // password: "vue006",
      userId: "",
      //bgImage: require("@/assets/images/photo-wide-4.jpg"),
      //logo: require("@/assets/images/logo.png"),
      //signInImage: require("@/assets/images/photo-long-3.jpg"),
    };
  },
  components: {},

  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13;
    },
  },

  methods: {
    handleClick() {
      this.busy = true;
      // Do something that takes some time
      this.sendOTP();
      setTimeout(() => {
        this.busy = false;
      }, 10000);
    },
    userKaydet() {
      console.log("userKaydet");
      this.cep = this.cep.replace("(", "");
      this.cep = this.cep.replace(")", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.slice(1);
      (async () => {
        const user = new Parse.User();
        user.set("username", this.cep);
        user.set("email", this.email);
        user.set("phone", parseInt(this.cep));
        user.set("cariKod", this.cep);
        user.set("password", "sanane");

        try {
          let userResult = await user.signUp();

          console.log("User signed up", userResult);
          localStorage.setItem("userInfo", JSON.stringify(userResult));
          //current_user = user;
          //commit("setUser", { uid: user.id });
          //this.$router.push(MainView )

          //router.push({ path: "/mainview/" + user.id, replace: true });
          window.location = "/newview/" + userResult.id;
        } catch (error) {
          console.error("Error while signing up user", error);
        }
      })();
    },

    promoGonder() {
      this.pointRegister = true;
      var myModal = document.getElementById("ModalRegister");
      var bsModal = new bootstrap.Modal(myModal);
      bsModal.show();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    goBack() {
      //window.history.go(-1);
      console.log("goBack!");
      this.point = true;
      this.cep = "";
    },
    sendOTP() {
      this.isLoading = true;

      this.errors = [];
      var pCheckResult = "";
      console.log("sendOTP yaptı");

      this.cep = this.cep.replace("(", "");
      this.cep = this.cep.replace(")", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.replace(" ", "");
      this.cep = this.cep.slice(1);

      console.log(this.cep);
      var number = this.cep;
      this.cep = this.cep;

      const cepparams = { cep: this.cep };
      console.log(cepparams);

      (async () => {
        console.log("geldi mi");
        try {
          Parse.User._clearCache();
          pCheckResult = await Parse.Cloud.run("pCheck", cepparams);
          console.log("pCheck   :::  " + pCheckResult);
          //var phone = pCheckResult[0].attributes.phone;

          if (pCheckResult != "") {
            var phone = pCheckResult[0].attributes.phone;
            console.log("KAKITLI MÜŞTERİ");
            console.log("xxxx :" + phone);

            const otpGenerator = require("otp-generator");
            var CryptoJS = require("crypto-js");

            const secret = "verysecretkey"; // Key for cryptograpy. Keep it secret

            //Generate a 6 digit numeric OTP
            const otp = otpGenerator.generate(6, {
              alphabets: false,
              upperCase: false,
              specialChars: false,
            });

            // var otpx = 516834;
            console.log("---OTP : " + otp);
            const ttl = 1 * 60 * 1000; //5 Minutes in miliseconds
            const expires = Date.now() + ttl; //timestamp to 5 minutes in the future
            const data = `${this.cep}.${otp}.${expires}`; // phone.otp.expiry_timestamp
            console.log(data);
            const hash = CryptoJS.SHA256(data, secret).toString(
              CryptoJS.enc.Hex
            );
            console.log(hash);
            //const hash = crypto.createHmac("sha256", key).update(data).digest("hex"); // creating SHA256 hash of the data
            //console.log(hash);
            const fullHash = `${hash}.${expires}`; // Hash.expires, format to send to the user
            // you have to implement the function to send SMS yourself. For demo purpose. let's assume it's called sendSMS
            //sendSMS(phone, `Your OTP is ${otp}. it will expire in 5 minutes`);
            this.hashm = fullHash;
            this.otpm = otp;
            console.log(fullHash);

            const otppart1 = otpGenerator.generate(7, {
              alphabets: false,
              upperCase: false,
              specialChars: false,
            });
            const otppart2 = otpGenerator.generate(5, {
              alphabets: false,
              upperCase: false,
              specialChars: false,
            });

            const hashOtp = otppart1 + otp + otppart2;
            console.log("OTP HASH'li : " + hashOtp);

            if (number.length == 10 && number != null) {
              this.point = false;
              var input = {
                mobile_number: number,
                action: "send_otp",
                num: hashOtp,
              };

              var optionAxios = {
                headers: {
                  "Content-Type": "text/html",
                },
              };
              axios
                .post(
                  "https://www.yakitpay.com/mapp/controller.php",
                  input,
                  optionAxios
                )
                .then(function () {
                  console.log("Yüklendi");
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              console.log("Telefon Numarasında hata var!");
              this.busy = false;
              toastbox("hata", 2000);
            }
          } else {
            if (number.length == 10 && number != null) {
              console.log("KAKITLI MÜŞTERİ DEĞİL");
              this.pointRegister = true;
              var myModal = document.getElementById("ModalRegister");
              var bsModal = new bootstrap.Modal(myModal);
              bsModal.show();
            }
            if (number.length < 10) {
              toastbox("hata", 20000);
            }
          }
        } catch (error) {
          console.error("Error while retrieving object pCheck", error);
        }
      })();
    },

    handleOnComplete(value) {
      console.log("OTP completed: ", value);
      verfiyOTP(value);
    },

    verfiyOTP(value) {
      console.log("OTP completed: ", this.smscode);

      const otpGenerator = require("otp-generator");
      var CryptoJS = require("crypto-js");
      const secret = "verysecretkey";
      let [hashValue, expires] = this.hashm.split(".");
      console.log("kontrol başlıyor");
      let now = Date.now();
      console.log("x-parseInt(expires) " + parseInt(expires));
      console.log("x-now " + now);
      if (now > expires) {
        console.log("expire oldu otp");
        return false;
      } else {
        console.log("expires değil otp");
        // Calculate new hash with the same key and the same algorithm
        //let data  = `${this.cepNo}.${this.otpm}.${expires}`;
        let data = `${this.otpm}`;

        //console.log(data);
        console.log("Kontrol ediliyor");
        console.log(value);
        console.log(data);

        if (data === this.smscode) {
          console.log("user :" + this.cep);
          this.cep = this.cep.replace("(", "");
          this.cep = this.cep.replace(")", "");
          this.cep = this.cep.replace(" ", "");
          this.cep = this.cep.replace(" ", "");
          this.cep = this.cep.replace(" ", "");
          this.cep = this.cep.slice(1);

          Parse.User.logIn(String(this.cep), this.password)
            .then(function (user) {
              console.log("Back4app çalıştı");

              if (typeof document !== "undefined") console.log(user.id);
              const newUser = { uid: user.id };
              console.log("Budur");
              console.log(user);
              console.log(newUser);
              localStorage.setItem("userInfo", JSON.stringify(user));
              console.log("*------------------ " + user.attributes.activeUser);
              //current_user = user;
              //commit("setUser", { uid: user.id });
              //this.$router.push(MainView )
              if (user.attributes.activeUser === "1") {
                router.push({ path: "/mainview/" + user.id, replace: true });
              } else {
                router.push({ path: "/newview/" + user.id, replace: true });
              }

              //window.location = "/mainview/" + user.id;
            })
            .catch(function (error) {
              console.log("servis gelen olumsuz cevap!!!");
              console.log("Error: " + error.code + " " + error.message);
            });
          console.log("Başarılı !");

          return true;
        }
        console.log("Başarısız !!! Tanımısız Kullanıcı");
        this.dismissCountDown = 10;

        toastbox("otphata", 2000);

        return false;
      }
    },
  },
};
</script>
